const galleryHu = {
    "file_upload_successful": "Fájlfeltöltés sikeres",
    "file_upload_error": "Hiba a fájl feltöltésekor",
    "attach_files": "Fájlok csatolása",
    "files_selected": "fájl kiválasztva",
    "upload": "Feltöltés",
    "uploading": "Folyamatban",
    "maximum_file_size": "A maximum megengedett file méret 25 MB.",
    "download": "Letöltés",
    "open_in_new_tab": "Megnyitás új lapon",
    "delete_file": "File törlése",
    "confirm_delete": "Biztosan törölni szeretné ezt a filet?",
    "image_in_use": "The kiválasztott kép használatban van a következő menü tételeknél:",
    "image_in_use_tip": "A kép törléséhez távolítsa el az érintett menütételekből.",
    "file_deleted_successfully": "File sikeresen törölve",
    "error_deleting_file": "Hiba a file törlésekor",
    "confirm_selection": "Kiválasztás",
    "file_deletion_forbidden": "A file törlése nem engedélyezett"
}
export default galleryHu;