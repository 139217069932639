const authHu = {
    "sign_in": "Bejelentkezés",
    "email": "Email cím",
    "password": "Jelszó",
    "sign_in_button": "Belépés",
    "invalid_credentials": "Hibás belépési adatok",
    "password_min_length": "A jelszónak legalább 8 karakter hosszúnak kell lennie",
    "passwords_do_not_match": "A jelszavak nem egyeznek",
    "invalid_token": "Érvénytelen token",
    "password_set": "Jelszó beállítva",
    "confirm_password": "Jelszó megerősítése",
    "confirm_title": "Quickpick Admin Regisztráció megerősítése",
    "confirm_text": "A fiók beállításához már csak egy biztonságos jelszó megadására van szükség",
    "set_password": "Jelszó beállítása",
    "account_activated": "Fiók aktiválva",
    "account_activated_text": "Mostantól be tudsz jelentkezni a felhasználói fiókodba a megadott email címeddel és jelszavaddal.",
    "continue_to_login": "Tovább a bejelentkezéshez",
    "forgotten_password": "Elfelejtettem a jelszavam",
    "unable_to_reset_password": "Szerver oldali hiba. Jelszóvisszaállítás sikertelen.",
    "reset_password_title": "Jelszó visszaállítása",
    "reset_password_text": "Kérjük, adja meg az email címét, amellyel regisztrálva lett a rendszerbe. Az email címre elküldjük a jelszó visszaállításához szükséges linket.",
    "send_reset_mail": "Jelszó visszaállító link küldése",
    "send_reset_mail_success": "Az email címre elküldtük a jelszó visszaállításához szükséges linket",
    "invalid_email": "Érvénytelen email cím",
    "reset_sent": "Üzenet elküldve",
    "change_password_title": "Jelszó megváltoztatása",
    "change_password_success": "Jelszó sikeresen megváltoztatva",
    "roles": {
        "SUPER_ADMIN": "Admin",
        "MENU_EDITOR": "Menü szerkesztés",
        "STAFF_MANAGER": "Személyzet szerkesztés"
    },
    "name": "Név",
    "permissions": "Jogosultságok",
    "create_user_permissions": "Jogosultságok létrehozása",
    "add_restaurant": "Étterem hozzáadása",
    "user_saved": "Felhasználó mentve",
    "has_admin_permissions": "Admin jogosultságok",
    "login_with_google": "Bejelentkezés Google-al",
    "missing_perrmissions": "Nincsenek meg a szükséges jogosultságok a hozzáféréshez"
}

export default authHu;