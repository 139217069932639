import React, { useMemo } from 'react'
import { HeaderRoute, usePathReconstructor } from '../config/pageRoutes'
import { Stack } from '@mui/material';
import { HeaderIconContainer, HeaderLinkContainer } from '../styles/header';
import { matchPath, useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';

type Props = {
    route: HeaderRoute;
}

const HeaderLink = ({ route }: Props) => {
    const location = useLocation();
    const { copyParamsFromPath } = usePathReconstructor();

    const isSelected = useMemo(() => {
        return matchPath(route.mainRoute.path, location.pathname) != null
    }, [location.pathname])

    return (
        <ThemeProvider theme={{ isSelected }}>
            <HeaderLinkContainer>
                <HeaderIconContainer
                    key={route.mainRoute.path}
                    to={copyParamsFromPath(route.mainRoute.path)}
                >
                    <Stack>
                        {route.icon}
                    </Stack>
                </HeaderIconContainer>
            </HeaderLinkContainer>
        </ThemeProvider>
    )
}

export default HeaderLink