import { MenuCategory } from "./MenuCategory";
import { MenuItem } from "./MenuItem";

export interface postSaveMenuDto {
    menu: saveCategoryDto[];
}

export interface saveCategoryDto {
    id?: number;
    translations: categoryTranslation[];
    isActive: boolean;
    order: number;
    menuItems: saveMenuItemDto[];
}

export interface categoryTranslation {
    isoCode: string;
    title: string;
}

export interface saveMenuItemDto {
    id?: number;
    imageUrl: string;
    isActive: boolean;
    priceGross: number;
    order: number;
    translations: menuItemTranslation[];
}

export interface menuItemTranslation {
    id?: number;
    isoCode: string;
    title: string;
    description: string;
}

export function generatePostSaveMenuDto(menuCategories: MenuCategory[]): postSaveMenuDto {
    const categories: saveCategoryDto[] = menuCategories.map((category, i) => {
        return {
            id: category.id > 0 ? category.id : undefined,
            isActive: category.isActive,
            order: i + 1,
            menuItems: category.menuItems.map((menuItem, i) => generateSaveMenuItemDto(menuItem, i + 1)),
            translations: generateCategoryTranslations(category)
        }
    })
    return {
        menu: categories,
    }
}

function generateCategoryTranslations(category: MenuCategory): categoryTranslation[] {
    return category.translations.map(translation => {
        return {
            isoCode: translation.isoCode,
            title: translation.title,
            description: ""
        }
    });
}

function generateSaveMenuItemDto(menuItem: MenuItem, order: number): saveMenuItemDto {
    return {
        id: menuItem.id > 0 ? menuItem.id : undefined,
        imageUrl: menuItem.imageUrl != null ? menuItem.imageUrl : "",
        isActive: menuItem.isActive,
        priceGross: menuItem.priceGross,
        order: order,
        translations: generatemMenuItemTranslations(menuItem)
    }
}

function generatemMenuItemTranslations(menuItem: MenuItem): menuItemTranslation[] {
    return menuItem.translations.map(translation => {
        return {
            id: translation.id > 0 ? translation.id : undefined,
            isoCode: translation.isoCode,
            title: translation.title,
            description: translation.description
        }
    });
}