 const commonEn = {
    "restaurant": "Restaurant",
    "restaurant_not_found": "The restaurant is not available",
    "table_not_available": "Orders cannot be placed at this table",
    "note": "Note",
    "order": "Order",
    "internal_error": "Request unsuccessful. The server responded with the following error: ",
    "no_results": "No results",
    "cancel": "Cancel",
    "save": "Save",
    "back": "Back",
    "search": "Search",
    "search_for_file_names": "Search for file names",
    "delete": "Delete",
    "search_by_restaurant": "Keresés étterem szerint",
    "available": "Available",
    "unavailable": "Unavailable",
    "name": "Name",
    "status": "Status",
    "description": "Description",
    "price": "Price",
    "add": "Add",
    "phone": "Phone",
    "internal_server_error": "Internal server error",
 }

 export default commonEn;