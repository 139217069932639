import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router';
import Header from './Header';

export default function DashboardLayout() {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Box
                    id="main-container"
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Header />
                    <Box style={{ paddingLeft: "80px" }} pt={3}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </>
    );
}