import { Alert, AlertTitle, Box, Button, Container, Paper } from '@mui/material'
import { loginPageRoute, usePathReconstructor } from '../config/pageRoutes'
import { useTranslation } from 'react-i18next'

type Props = {}

const SignupSuccessPage = (props: Props) => {
    const { t } = useTranslation();
    const { copyParamsFromPath } = usePathReconstructor();

    return (
        <Container>
            <div style={{ maxWidth: "600px", margin: "auto" }}>
                <Box pt={8}>
                    <Paper>
                        <Alert severity='success' variant="filled" style={{ color: "white" }}>
                            <AlertTitle>
                                {t("auth.account_activated")}
                            </AlertTitle>
                            {t("auth.account_activated_text")}
                        </Alert>
                        <Box pt={2}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="secondary"
                                href={copyParamsFromPath(loginPageRoute.path)}
                            >
                                {t("auth.continue_to_login")}
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </div>
        </Container>
    )
}

export default SignupSuccessPage