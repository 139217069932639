const authEn = {
    "sign_in": "Sign in",
    "email": "Email Address",
    "password": "Password",
    "sign_in_button": "Sign In",
    "invalid_credentials": "Invalid credentials",
    "password_min_length": "The password must be at least 8 characters long",
    "passwords_do_not_match": "Passwords do not match",
    "invalid_token": "Invalid token",
    "password_set": "Password set",
    "confirm_password": "Confirm password",
    "confirm_title": "Quickpick Admin Registration Confirmation",
    "confirm_text": "To set up your account, you just need to provide a secure password",
    "set_password": "Set Password",
    "account_activated": "Account activated",
    "account_activated_text": "You can now log in to your user account with your provided email address and password",
    "continue_to_login": "Continue to login",
    "forgotten_password": "Forgotten password",
    "unable_to_reset_password": "Internal error, unable to reset password.",
    "reset_password_title": "Reset password",
    "reset_password_text": "Please enter the email address used for with your account. We will send the link to reset the password to the email address.",
    "send_reset_mail": "Send password reset link",
    "send_reset_mail_success": "An email has been sent to the provided address with further instructions",
    "invalid_email": "Invalid email address",
    "reset_sent": "Email sent",
    "change_password_title": "Change password",
    "change_password_success": "Password changed successfully",
    "roles": {
        "SUPER_ADMIN": "Admin",
        "MENU_EDITOR": "Menu editing",
        "STAFF_MANAGER": "Staff management"
    },
    "name": "Name",
    "permissions": "Permissions",
    "create_user_permissions": "Create user permissions",
    "add_restaurant": "Add restaurant",
    "user_saved": "User saved",
    "has_admin_permissions": "Admin permissions",
    "login_with_google": "Login with Google",
    "missing_perrmissions": "You do not have the required permissions to access this page"
}

export default authEn;