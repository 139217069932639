import React, { useContext } from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragHandleContainer } from '../styles/containers';
import { SortableItemContext } from '../sortable/SortableItem';
import { Box } from '@mui/material';
import { primary } from '../styles/colors';


type Props = {}

const MenuItemEditCategoryRowHandle = (props: Props) => {
    const { attributes, listeners, ref } = useContext(SortableItemContext);

    return (
        <DragHandleContainer {...attributes} {...listeners} ref={ref} style={{ width: "100%", height: "100%" }} textAlign={"center"}>
            <Box pt={0.6}>
                <DragIndicatorIcon
                    style={{ fontSize: "28px", marginBottom: "-5px", color: primary.dark }}
                />
            </Box>
        </DragHandleContainer>
    )
}

export default MenuItemEditCategoryRowHandle