import React, { useContext } from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragHandleContainer } from '../styles/containers';
import { SortableItemContext } from '../sortable/SortableItem';


type Props = {}

const MenuItemEditRowHandle = (props: Props) => {
    const { attributes, listeners, ref } = useContext(SortableItemContext);

    return (
        <DragHandleContainer  {...attributes} {...listeners} ref={ref} style={{ width: "100%", height: "100%" }} textAlign={"center"}>
            <DragIndicatorIcon
                style={{ fontSize: "30px", marginBottom: "-5px" }}
            />
        </DragHandleContainer>
    )
}

export default MenuItemEditRowHandle