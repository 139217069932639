import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import SnackbarContext from '../contexts/SnackbarContext';
import { putConfirmSignup } from '../config/apiRoutes';
import { useNavigate, useParams } from 'react-router';
import { confirmSignupDto } from '../models/User';
import { useTranslation } from 'react-i18next';
import { signUpSuccessRoute, usePathReconstructor } from '../config/pageRoutes';

type Props = {}

const ConfirmSignupPage = (props: Props) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationEnabled, setValidationEnabled] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const snackbar = useContext(SnackbarContext);
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { copyParamsFromPath } = usePathReconstructor();

    useEffect(() => {
        document.title = "Quickpick Admin";
    }, []);

    function handleNewPasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setNewPassword(e.target.value);
    }

    function handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setConfirmPassword(e.target.value);
    }

    useEffect(() => {
        if (newPassword.length < 8) {
            setNewPasswordError(t("auth.password_min_length"));
        } else {
            setNewPasswordError("");
        }
    }, [newPassword]);

    useEffect(() => {
        if (confirmPassword !== newPassword) {
            setConfirmPasswordError(t("auth.passwords_do_not_match"));
        } else {
            setConfirmPasswordError("");
        }
    }, [confirmPassword, newPassword]);

    function handleConfirmClick() {
        setValidationEnabled(true);
        if (newPasswordError.length > 0 || confirmPasswordError.length > 0) {
            return;
        }
        sendConfirmation();
    }

    async function sendConfirmation() {
        const { token, userId } = params;
        if (token == null || userId == null) {
            return snackbar.showMessage(t("auth.invalid_token"), "error");
        }
        const data: confirmSignupDto = {
            userId: parseInt(userId),
            token: token,
            password: newPassword
        }
        const response = await fetch(putConfirmSignup, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            navigate(copyParamsFromPath(signUpSuccessRoute.path));
        } else {
            snackbar.showMessage(t("auth.invalid_token"), "error");
        }
    }

    return (
        <Container>
            <div style={{ maxWidth: "600px", margin: "auto" }}>
                <Box pt={6}>
                    <Paper elevation={3}>
                        <Box p={1}>
                            <Typography fontSize={"1.4rem"} pb={1} >
                                {t("auth.confirm_title")}
                            </Typography>
                            <Typography py={2}>
                                {t("auth.confirm_text")}
                            </Typography>
                            <Box py={1}>
                                <TextField
                                    label={t("auth.password")}
                                    type="password"
                                    name="new-password"
                                    fullWidth
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    error={validationEnabled && newPasswordError.length > 0}
                                    helperText={validationEnabled && newPasswordError}
                                />
                            </Box>
                            <Box py={1}>
                                <TextField
                                    label={t("auth.confirm_password")}
                                    type="password"
                                    fullWidth
                                    name="confirm-new-password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    error={validationEnabled && confirmPasswordError.length > 0}
                                    helperText={validationEnabled && confirmPasswordError}
                                />
                            </Box>
                            <Box py={1}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={handleConfirmClick}
                                >
                                    {t("auth.set_password")}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </div>
        </Container>
    )
}

export default ConfirmSignupPage;