import { Role, RoleType, putRoleDto } from "./Role";

export interface User {
    id: number;
    email: string;
    name: string;
    roles: Role[];
}

export const defaultUser: User = {
    id: 0,
    email: "",
    name: "",
    roles: [],
}

export function hasRole(user: User, roleType: RoleType): boolean {
    return user.roles.some(role => role.type === roleType);
}

export interface putUserDto {
    id?: number;
    email: string;
    name: string;
    roles: putRoleDto[];
}

export interface confirmSignupDto {
    password: string;
    token: string;
    userId: number;
}

export interface resetPasswordDto {
    password: string;
    token: string;
    userId: number;
}