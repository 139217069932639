import { Box, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import SearchInput from '../common/SearchInput'
import { OptionsButton, SpecialButton, StandardButton } from '../styles/buttons'
import { MenuHeaderDivider } from '../styles/header';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import SelectButton from '../common/SelectButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
    onSave: () => void;
    onNewCategoryClick: () => void;
    search: string;
    setSearch: (search: string) => void;
}

const MenuEditorHeader = (props: Props) => {
    const { t } = useTranslation();
    const { selectedRestaurant, restaurantSettings, setSelectedRestaurant } = useContext(UserContext);

    function selectOptions() {
        return restaurantSettings.map((restaurantSetting) => {
            return {
                value: restaurantSetting.restaurantShortName,
                label: restaurantSetting.name
            }
        });
    }

    function handleRestaurantChange(value: string | null) {
        const restaurant = restaurantSettings.find(restaurant => restaurant.restaurantShortName === value);
        if (restaurant) {
            setSelectedRestaurant(restaurant);
        }
    }

    function handleMenuButtonClick() {
        if (selectedRestaurant == null) return;
        window.open(`${process.env.REACT_APP_FRONTEND_URL}/menu/${selectedRestaurant?.restaurantShortName}`, '_blank');
    }

    return (
        <Box pr={1}>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Typography variant="h4" fontWeight={"bold"} fontSize="26px">
                        {t("menu.menu_editor")}
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchInput
                        search={props.search}
                        setSearch={props.setSearch}
                    />
                </Grid>
                <Grid item>
                    {/*
                      <OptionsButton>
                        <img src={"../assets/list.svg"} style={{ marginTop: "2px", marginLeft: "1px" }} />
                    </OptionsButton>
                    */}

                </Grid>
            </Grid>
            <Box pt={4}>
                <Grid container spacing={2}>
                    {restaurantSettings.length > 1 && (
                        <Grid item xs>
                            <SelectButton
                                options={selectOptions()}
                                onChange={handleRestaurantChange}
                                selectedValue={selectedRestaurant != null ? selectedRestaurant.restaurantShortName : null}
                            />
                        </Grid>
                    )}
                    {restaurantSettings.length < 2 && (
                        <Grid item xs>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Typography pt={2} variant="h4" fontWeight={"bold"} fontSize="20px">
                                        {selectedRestaurant?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <StandardButton
                            onClick={props.onSave}
                        >
                            {t("common.save")}
                        </StandardButton>
                    </Grid>
                    <Grid item>
                        <SpecialButton
                            onClick={handleMenuButtonClick}
                        >
                            {t("menu.menu")}
                            <OpenInNewIcon className="end-icon" />
                        </SpecialButton>
                    </Grid>
                    <Grid item>
                        <SpecialButton
                            onClick={props.onNewCategoryClick}
                        >
                            {t("menu.new_category")}
                            <AddIcon className="end-icon" />
                        </SpecialButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={2}>
                <MenuHeaderDivider />
            </Box>
        </Box>
    )
}

export default MenuEditorHeader