import React from 'react'
import { LangSelectButton } from '../styles/inputs'
import { ThemeProvider } from 'styled-components';

type Props = {
    imgUrl: string;
    isSelected: boolean;
    onClick: () => void;
}

const LangSelectorButton = ({ imgUrl, isSelected, onClick }: Props) => {
    return (
        <ThemeProvider theme={{ isSelected: isSelected }}>
            <LangSelectButton
                onClick={onClick}
            >
                <img
                    src={imgUrl}
                />
            </LangSelectButton>
        </ThemeProvider>
    )
}

export default LangSelectorButton