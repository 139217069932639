import { useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";

export interface ILoader {
    loading: boolean;
    error: boolean;
    errorMessage: string;
    successMessage?: string;
}

export const defaultLoader: ILoader = {
    loading: false,
    error: false,
    errorMessage: "",
}

export interface FetchCallResponse<T> {
    status: number;
    ok: boolean;
    data?: T;
    parsed: boolean;
}

interface IErrorBody {
    statusCode: number;
    message: string | string[]
}

export default function useFetch(loadByDefault: boolean = false, isFile: boolean = false) {
    const { user, getToken } = useContext(AuthContext);
    const [loader, setLoader] = useState<ILoader>({
        ...defaultLoader,
        loading: loadByDefault
    });

    async function call<T, Q>(method: string, apiRoute: string, body?: T): Promise<FetchCallResponse<Q>> {
        if (user == null) {
            setLoader({
                loading: false,
                error: true,
                errorMessage: "User is not logged in",
            });
            return {
                status: 401,
                ok: false,
                parsed: false
            }
        }
        setLoader({ ...loader, loading: true, error: false });
        const token = await getToken();
        if (token == null) {
            throw new Error("Firebase authentication error.");
        }
        const options = isFile ? constructFileUploadFetchOptions(method, token, body) : constructFetchOptions(method, token, body);
        const response = await fetch(apiRoute, options as RequestInit);
        if (response.ok) {
            try {
                const data = await response.json();
                setLoader({
                    loading: false,
                    error: false,
                    errorMessage: "",
                });
                return {
                    status: response.status,
                    ok: response.ok,
                    data,
                    parsed: true
                };
            } catch (e) {
                setLoader({
                    loading: false,
                    error: true,
                    errorMessage: "Invalid response from server",
                });
                return {
                    status: response.status,
                    ok: response.ok,
                    parsed: false
                };
            }
        } else {
            const errorMessage = await constructErrorMessage(response);
            setLoader({
                loading: false,
                error: true,
                errorMessage: errorMessage,
            });
            return {
                status: response.status,
                ok: response.ok,
                parsed: false
            };
        }
    }

    return {
        ...loader,
        call
    }
}

export function constructFetchOptions(method: string, idToken: string, data?: any) {
    const options = {
        method: method,
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer'
    }
    if (method != "GET" && data != null) {
        (options as any)["body"] = JSON.stringify(data);
    }
    return options;
}

function constructFileUploadFetchOptions(method: string, idToken: string, data?: any) {
    const options = {
        method: method,
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Authorization": `Bearer ${idToken}`
        },

        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',
        body: data
    }
    return options;
}

export async function constructErrorMessage(response: Response) {
    try {
        const json: IErrorBody = await response.json();
        const message = typeof json.message === "string" ? json.message : json.message.join(", ");
        return message;
    } catch (e) {
        return `[${response.status}]: an unknown error occured :'(`;
    }
}