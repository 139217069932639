const galleryEn = {
    "file_upload_successful": "File upload successful",
    "file_upload_error": "Error uploading file",
    "attach_files": "Attach Files",
    "files_selected": "files Selected",
    "upload": "Upload",
    "uploading": "Uploading",
    "maximum_file_size": "Maximum allowed upload size is 25 MB.",
    "download": "Download",
    "open_in_new_tab": "Open in new tab",
    "delete_file": "Delete file",
    "confirm_delete": "Are you sure you want to delete this file?",
    "image_in_use": "The selected image is in use in the following active menu items:",
    "image_in_use_tip": "In order to delete this file, please remove it from any active listings.",
    "file_deleted_successfully": "File deleted successfully",
    "error_deleting_file": "Error deleting file",
    "confirm_selection": "Confirm selection",
    "file_deletion_forbidden": "File deletion forbidden"
}
export default galleryEn;