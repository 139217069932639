import { Grid, Box, Typography, Alert, Container } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import AuthContext, { AuthState } from '../contexts/AuthContext';
import { useNavigate } from 'react-router';
import { menuEditorRoute, usePathReconstructor } from '../config/pageRoutes';
import LangSelector from '../lang/LangSelector';
import { LoginForm, LoginPageContainer, LoginPageContainerOverlay } from '../styles/containers';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/Footer';
import { LoginTextInput } from '../styles/inputs';
import { GoogleLoginButton, GoogleLoginButtonTextContainer, GoogleLoginIconContainer, LoginButton } from '../styles/buttons';
import { primary } from '../styles/colors';
import GoogleIcon from '@mui/icons-material/Google';

type Props = {}

const LoginPage = (props: Props) => {
    const { t } = useTranslation();
    const { copyParamsFromPath } = usePathReconstructor()
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const { login, user, authState, errorMessage } = useContext(AuthContext);
    const navigate = useNavigate();
    const [requestErrorMessage, setRequestErrorMessage] = React.useState<string>('');


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setRequestErrorMessage("");
        const success = await login(email, password);
        if (!success) setRequestErrorMessage(t("auth.invalid_credentials"));
    };

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
    }

    function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        setPassword(e.target.value);
    }

    useEffect(() => {
        if (authState === AuthState.LOGGED_IN) {
            navigate(copyParamsFromPath(menuEditorRoute.path));
        }
    }, [authState, user]);

    return (
        <>
            <LoginPageContainer>
                <LoginPageContainerOverlay>
                    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
                        <Box pt={2} px={4}>
                            <Grid container>
                                <Grid item>
                                    <img
                                        src={process.env.REACT_APP_PUBLIC_URL + '/assets/logo.svg'}
                                        width={"80px"}
                                    />
                                </Grid>
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    <Box pt={1}>
                                        <LangSelector />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Container style={{ flex: "1", maxWidth: "1600px" }}>
                            <Box pt={3}>
                                <Grid container>
                                    <Grid item xs>
                                    </Grid>
                                    <Grid xs item style={{ maxWidth: "500px" }}>
                                        <LoginForm px={6} pt={3} pb={8}>
                                            <Typography fontWeight={"bold"} fontSize="30px" mt={1} fontFamily="Avenir Next" color={primary.deep}>
                                                {t("auth.sign_in")}
                                            </Typography>
                                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                                <Box py={2}>
                                                    <LoginTextInput
                                                        placeholder={t("auth.email")}
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                    />
                                                </Box>

                                                <Box py={2}>
                                                    <LoginTextInput
                                                        placeholder={t("auth.password")}
                                                        type="password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                    />
                                                </Box>
                                                <Box py={2}>
                                                    <GoogleLoginButton>
                                                        <Grid container>
                                                            <Grid item xs textAlign={"left"}>
                                                                <GoogleLoginButtonTextContainer>
                                                                    {t("auth.login_with_google")}
                                                                </GoogleLoginButtonTextContainer>
                                                            </Grid>
                                                            <Grid item textAlign={"right"} >
                                                                <GoogleLoginIconContainer>
                                                                    <GoogleIcon />
                                                                </GoogleLoginIconContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </GoogleLoginButton>
                                                </Box>
                                                {errorMessage != null && (
                                                    <Box py={2}>
                                                        <Alert severity='error' variant="filled" >
                                                            {errorMessage}
                                                        </Alert>
                                                    </Box>
                                                )}
                                                {requestErrorMessage && (
                                                    <Box py={2}>
                                                        <Alert severity='error' variant="filled" >
                                                            {requestErrorMessage}
                                                        </Alert>
                                                    </Box>)}
                                                <LoginButton
                                                    type="submit"
                                                >
                                                    {t("auth.sign_in_button")}
                                                </LoginButton>
                                            </Box>
                                        </LoginForm>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                        <Footer />
                    </div>
                </LoginPageContainerOverlay>
            </LoginPageContainer>
        </>
    );
}

export default LoginPage