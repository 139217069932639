import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import MenuEditorHeader from '../menuEditor/MenuEditorHeader'
import useFetch from '../hooks/useFetch'
import { getFullMenu, postUpdateMenu } from '../config/apiRoutes'
import { MenuCategory, MenuCategorySearchType, SerializedMenuCategory, defaultMenuCategoryTranslations, deserializeMenuCategories, generateCategorySearch } from '../models/MenuCategory'
import MenuCategoryEditor from '../menuEditor/MenuCategoryEditor'
import { generatePostSaveMenuDto, postSaveMenuDto } from '../models/UpdateMenuDto'
import SnackbarContext from '../contexts/SnackbarContext'
import { useTranslation } from 'react-i18next'
import UserContext from '../contexts/UserContext'
import { Typography } from '@mui/material'

export enum MenuEditorMode {
  CATEGORIES = "categories",
  MENU = "menu",
  PHOTOS = "photos",
}

type Props = {}

const MenuEditorPage = (props: Props) => {
  const { call, error, errorMessage, loading } = useFetch();
  const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
  const [editedMenuCategories, setEditedMenuCategories] = useState<MenuCategory[]>([]);
  const { showMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const { selectedRestaurant } = useContext(UserContext);
  const [search, setSearch] = useState("");
  const openIndexes = useRef<number[]>([]);
  const allowReorder = !search;

  const displayedCategories = useMemo(() => {
    return editedMenuCategories.map(category => generateCategorySearch(category, search)).filter(category => category.searchType !== MenuCategorySearchType.NONE);
  }, [editedMenuCategories, search]);

  useEffect(() => {
    if (selectedRestaurant) {
      requestMenu();
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    setEditedMenuCategories(menuCategories);
  }, [menuCategories]);

  async function requestMenu() {
    if (!selectedRestaurant) {
      return;
    }
    const response = await call<undefined, SerializedMenuCategory[]>("GET", getFullMenu.replace(":restaurantShortName", selectedRestaurant.restaurantShortName));
    if (response.ok && response.data) {
      setMenuCategories(deserializeMenuCategories(response.data));
    }
  }

  async function saveChanges() {
    if (!selectedRestaurant) {
      return;
    }
    const updateData: postSaveMenuDto = generatePostSaveMenuDto(editedMenuCategories);
    const response = await call<postSaveMenuDto, undefined>("POST", postUpdateMenu.replace(":restaurantShortName", selectedRestaurant.restaurantShortName), updateData);
    if (response.ok) {
      showMessage(t("menu.menu_saved"), "success");
      requestMenu();
    }
  }

  useEffect(() => {
    if (error) {
      showMessage(errorMessage, "error");
    }
  }, [error]);

  function onNewCategoryClick() {
    const newOrder = editedMenuCategories.length > 0 ? editedMenuCategories[editedMenuCategories.length - 1].order + 1 : 1;
    const newCategory: MenuCategory = {
      id: -1,
      isActive: false,
      order: newOrder,
      restaurantShortName: selectedRestaurant?.restaurantShortName || "",
      translations: defaultMenuCategoryTranslations,
      menuItems: []
    }
    setEditedMenuCategories([...editedMenuCategories, newCategory]);
  }

  function onCategoryToggled(index: number, ){

  }

  return (
    <>
      <MenuEditorHeader
        onSave={saveChanges}
        onNewCategoryClick={onNewCategoryClick}
        search={search}
        setSearch={setSearch}
      />
      <div>
        <MenuCategoryEditor
          searchedCategories={displayedCategories}
          categories={editedMenuCategories}
          setCategories={setEditedMenuCategories}
          isReorderAllowed={allowReorder}
        />
        {!loading && displayedCategories.length === 0 && (
          <Typography>
            No results
          </Typography>
        )}
      </div>
    </>
  )
}

export default MenuEditorPage