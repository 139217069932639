import styled from "styled-components";
import { background, primary } from "./colors";
import { styled as muiStyled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export const HeaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    background: ${primary.deep};
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    overflow: hidden;
    width: ${props => props.theme.isOpen ? "200px" : "53.5px"};
    transition: 0.3s width;
`;

export const HeaderLinkContainer = styled.div`
    padding-left: 5px;
    padding-right: 5px;
`;

export const HeaderIconContainer = styled(Link)`
    background: ${props => props.theme.isSelected ? background.deep : primary.dark};
    color: ${props => props.theme.isSelected ? "black" : "white"};
    border-radius: 50%;
    padding: 10px;
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    transition: 0.3s background;
    svg{
        font-size: 1.45rem;
    }
    &:hover{
        background: ${primary.light};
    }
`;
export const HeaderLogo = styled.img`
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
`;

export const HeaderButton = styled.button`
    background: ${primary.dark};
    cursor: pointer;
    border: none;
    outline: none;
    color: white;
    border-radius: 50%;
    padding: 10px;
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    transition: 0.3s background;
    svg{
        font-size: 1.45rem;
    }
    &:hover{
        background: ${primary.light};
    }
`;

export const MenuHeaderDivider = styled.div`
    background: ${primary.light};
    height: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const AvailabilityContainer = muiStyled(Box)`
    background: ${background.main};
    padding: 8px 15px;
`;