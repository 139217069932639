import React, { useContext, useEffect } from 'react'
import AuthContext, { AuthState } from '../contexts/AuthContext'
import { useNavigate } from 'react-router';
import { menuEditorRoute } from '../config/pageRoutes';

type Props = {}

const WelcomePage = (props: Props) => {
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (authState === AuthState.LOGGED_IN) {
            navigate(menuEditorRoute.path.replace(":lang", "hu"));
        } 
    }, [authState]);

    return (
        <></>
    )
}

export default WelcomePage