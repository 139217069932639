import React from 'react'
import { MenuCategory, MenuCategorySearchResult } from '../models/MenuCategory'
import EditedMenuCategory from './EditedMenuCategory';
import { SortableList } from '../sortable/SortableList';

type Props = {
    searchedCategories: MenuCategorySearchResult[];
    categories: MenuCategory[];
    setCategories: (categories: MenuCategory[]) => void;
    isReorderAllowed: boolean;
}

const MenuCategoryEditor = ({ searchedCategories, categories, setCategories, isReorderAllowed }: Props) => {
    function setCategory(newCategory: MenuCategory) {
        const newCategories = categories.map(category => category.id === newCategory.id ? newCategory : category);
        setCategories(newCategories);
    }

    function onDeleteClick(id: number) {
        setCategories(categories.filter(category => category.id !== id));
    }

    function getForcedMenuItems(categoryId: number) {
        const searchedResult = searchedCategories.find(searched => searched.category.id === categoryId);
        if (!searchedResult) return [];
        return searchedResult.items;
    }

    return (
        <div>
            {isReorderAllowed && (
                <SortableList
                    items={categories}
                    onChange={setCategories}
                    renderItem={(category) => <EditedMenuCategory
                        key={category.id}
                        menuCategory={category}
                        setMenuCategory={setCategory}
                        onDeleteClick={() => onDeleteClick(category.id)}
                        isReorderAllowed={true}
                    />}
                    displayMode="list"
                />
            )}
            {!isReorderAllowed && (
                <>
                    {searchedCategories.map(searched => (
                        <EditedMenuCategory
                            key={searched.category.id}
                            menuCategory={searched.category}
                            setMenuCategory={setCategory}
                            onDeleteClick={() => onDeleteClick(searched.category.id)}
                            forcedMenuItems={getForcedMenuItems(searched.category.id)}
                            isReorderAllowed={false}
                            forceOpen={true}
                        />
                    ))}
                </>
            )}
        </div>
    )
}

export default MenuCategoryEditor