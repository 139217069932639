import React from 'react'

type Props = {}

const LabeledSwitch = (props: Props) => {
  return (
    <div>
        
    </div>
  )
}

export default LabeledSwitch