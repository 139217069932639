import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom'

const supportedLanguages = ["en", "hu"];
const fallbackLanguage = "en";

export default function LocalizationHandler() {
    const params = useParams();
    const { i18n } = useTranslation();
    const [loadiing, setLoading] = useState(true);

    useEffect(() => {
        const selectedLanguage = params.lang as string;
        if (params.lang == null) {
            return setFallbackLanguage();
        }
        const languageLowerCase = selectedLanguage.toLowerCase();
        const isValid = supportedLanguages.includes(languageLowerCase);
        if (isValid) {
            i18n.changeLanguage(languageLowerCase);
            setLoading(false);
        } else {
            setFallbackLanguage();
        }

    }, []);

    function setFallbackLanguage() {
        i18n.changeLanguage(fallbackLanguage);
        setLoading(false);
    }

    return (
        <>
            {!loadiing && (<><Outlet /></>)}
        </>
    )
}
