import { Alert, Snackbar } from '@mui/material';
import React from 'react'

type Props = {
    children: any;
}

interface SnackbarContextState {
    showMessage: (message: string, severity: "success" | "error" | "warning" | "info") => void;
}

const SnackbarContext = React.createContext<SnackbarContextState>(null as any);

export const SnackbarProvider = (props: Props) => {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState<"success" | "error" | "warning" | "info" | undefined>("success");

    function handleClose() {
        setOpen(false);
    };

    function showMessage(newMessage: string, newSeverity: "success" | "error" | "warning" | "info") {
        setSeverity(newSeverity);
        setMessage(newMessage);
        setOpen(true);
    }

    const state = {
        showMessage
    }

    return (
        <SnackbarContext.Provider value={state} >
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}

            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            {props.children}
        </SnackbarContext.Provider>
    )
}

export default SnackbarContext