import React, { useEffect, useRef, useState } from 'react'
import { NumericInputContainer, NumericInputStyle, NumericStepButton, UnitOfMeasureText } from '../styles/inputs'
import { Grid } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ThemeProvider } from 'styled-components';

type Props = {
    unitOfMeasure?: string;
    value: number;
    setValue: (newValue: number) => void;
}

const NumericInput = ({ unitOfMeasure, value, setValue }: Props) => {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const timer = useRef<any>(null);
    const stepInterval = useRef(200); // Initial interval for holding button
    const [stepUpFocused, setStepUpFocused] = useState(false);
    const [stepDownFocused, setStepDownFocused] = useState(false);

    useEffect(() => {
        setValue(inputValue)
    }, [inputValue])

    const updateValue = (increment: number) => {
        setInputValue((oldValue) => oldValue + increment);
    };

    const startStepping = (increment: number) => {
        updateValue(increment);
        timer.current = setTimeout(() => {
            startStepping(increment);
        }, stepInterval.current);
        stepInterval.current = Math.max(20, stepInterval.current * 0.8); // Accelerate the stepping
    };

    const stopStepping = () => {
        clearTimeout(timer.current);
        stepInterval.current = 200; // Reset interval
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        }
    }, [])

    return (
        <ThemeProvider theme={{ isFocused: isInputFocused || stepUpFocused || stepDownFocused }}>
            <NumericInputContainer>
                <Grid container>
                    <Grid item xs>
                        <NumericInputStyle
                            type="number"
                            ref={inputRef}
                            value={inputValue}
                            onChange={(e) => setInputValue(parseFloat(e.target.value))}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                    </Grid>
                    <Grid item>
                        <UnitOfMeasureText>
                            {unitOfMeasure}
                        </UnitOfMeasureText>
                    </Grid>
                    <Grid item >
                        <div onMouseLeave={stopStepping}>
                            <NumericStepButton
                                onMouseDown={() => startStepping(1)}
                                onMouseUp={stopStepping}
                                onFocus={() => setStepUpFocused(true)}
                                onBlur={() => setStepUpFocused(false)}
                            >
                                <KeyboardArrowUpIcon />
                            </NumericStepButton>
                            <NumericStepButton
                                onMouseDown={() => startStepping(-1)}
                                onMouseUp={stopStepping}
                                onFocus={() => setStepDownFocused(true)}
                                onBlur={() => setStepDownFocused(false)}
                            >
                                <KeyboardArrowDownIcon />
                            </NumericStepButton>
                        </div>
                    </Grid>
                </Grid>
            </NumericInputContainer>
        </ThemeProvider>
    )
}

export default NumericInput