import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { User, defaultUser } from '../models/User';
import { Outlet } from 'react-router';
import useFetch from '../hooks/useFetch';
import { userProfilePath } from '../config/apiRoutes';
import AuthContext, { AuthState } from './AuthContext';
import { Box } from '@mui/material';
import { ShardLoading } from '../styles/loading';
import { RestaurantSetting } from '../models/RestaurantSetting';
import { useTranslation } from 'react-i18next';

type Props = {}

export interface UserContextProps {
    user: User;
    selectedRestaurant: RestaurantSetting | null;
    setSelectedRestaurant: (restaurant: RestaurantSetting | null) => void;
    restaurantSettings: RestaurantSetting[];
}

const UserContext = createContext<UserContextProps>({} as any);

export const UserProvider = (props: Props) => {
    const auth = useContext(AuthContext);
    const [user, setUser] = useState<User>(defaultUser);
    const userLoader = useFetch(true);
    const [selectedRestaurant, setSelectedRestaurant] = useState<RestaurantSetting | null>(null);
    const { t } = useTranslation();

    const restaurantSettings = useMemo(() => {
        return user.roles
            .filter(role => role.restaurantSetting != null)
            .map(role => role.restaurantSetting) as RestaurantSetting[];
    }, [user.roles]);

    useEffect(() => {
        if (auth.authState === AuthState.LOGGED_IN && auth.user != null) {
            initUser();
        }
    }, [auth.authState, auth.user]);

    useEffect(() => {
        if (restaurantSettings.length > 0) {
            setSelectedRestaurant(restaurantSettings[0]);
        }
    }, [restaurantSettings]);

    async function initUser() {
        try {
            const response = await userLoader.call<undefined, User>("GET", userProfilePath);
            if (response.ok && response.data) {
                setUser(response.data);
            } else {
                if (response.status === 401) {
                    auth.setErrorMessage(t("auth.missing_perrmissions"));
                } else {
                    auth.setErrorMessage(t("common.internal_server_error"));
                }
                auth.logout();
            }
        } catch {
            auth.setErrorMessage(t("common.internal_server_error"));
            auth.logout();
        }
    }

    const state = {
        user,
        selectedRestaurant,
        setSelectedRestaurant,
        restaurantSettings,
    }

    return (
        <UserContext.Provider value={state}>
            {!userLoader.loading && !userLoader.error && (
                <Outlet />
            )}
            {userLoader.loading && (
                <Box pt={8} px={4} textAlign={"center"}>
                    <ShardLoading size="60px" />
                </Box>
            )}
        </UserContext.Provider>
    )
}

export default UserContext