import { Switch, styled } from "@mui/material";
import { errorColor, success } from "./colors";

export const PrimarySwitch = styled(Switch)`
    .MuiSwitch-switchBase{
        color: ${errorColor} !important;
    }
    .Mui-checked{
        color: ${success} !important;
    }
    .MuiSwitch-track{
        background-color: grey !important;    
    }
`;