import React from 'react'
import { DarkContainer, GradientDivider } from '../styles/containers'
import { Box, Grid, Typography } from '@mui/material'
import { FooterLink, HightlightedLink, SocialLink, SocialLinkContent } from '../styles/links'
import { highlight } from '../styles/colors'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LangSelector from '../lang/LangSelector'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation } from 'react-i18next'

type Props = {}

const Footer = (props: Props) => {
    const { t } = useTranslation();
    return (
        <Box px={4} py={1}>
            <DarkContainer>
                <Box py={3} px={6}>
                    <Grid container >
                        <Grid item>
                            <img
                                src={process.env.REACT_APP_PUBLIC_URL + '/assets/logo.svg'}
                                width={"70px"}
                            />
                            <Box pt={3}>
                                <Typography color="white" fontSize="16px" fontFamily="Avenir Next">
                                    Email: <HightlightedLink href="mailto:info@reservours.com">
                                        info@reservours.com
                                    </HightlightedLink>
                                </Typography>
                                <Typography color="white" fontSize="16px" fontFamily="Avenir Next" pt={1}>
                                    {t("common.phone")}: <span style={{ color: highlight.deep }}>
                                        info@reservours.com
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <div style={{ marginTop: "50px" }} />
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <Typography fontSize={"16px"} color="white" fontFamily="Avenir Next" fontWeight={"bold"} mb={2} >
                                        {t("footer.follow_us")}
                                    </Typography>
                                    <SocialLink href="https://www.facebook.com/reservours/" >
                                        <SocialLinkContent>
                                            <Box px={2} pt={1.2} pb={0.9}>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <FacebookOutlinedIcon />
                                                    </Grid>
                                                    <Grid item>
                                                        <Box pt={0.3}>
                                                            Facebook
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </SocialLinkContent>
                                    </SocialLink>
                                </Grid>
                                <Grid item>
                                    <SocialLink href="https://www.instagram.com/reservours/" >
                                        <Box pt={5}>
                                            <SocialLinkContent>
                                                <Box px={2} pt={1.2} pb={0.9}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <InstagramIcon />
                                                        </Grid>
                                                        <Grid item>
                                                            <Box pt={0.3}>
                                                                Instagram
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </SocialLinkContent>
                                        </Box>
                                    </SocialLink>
                                </Grid>
                                <Grid item>
                                    <SocialLink href="https://hu.linkedin.com/company/reservours" >
                                        <Box pt={5}>
                                            <SocialLinkContent>
                                                <Box px={2} pt={1.2} pb={0.9}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <LinkedInIcon />
                                                        </Grid>
                                                        <Grid item>
                                                            <Box pt={0.3}>
                                                                LinkedIn
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </SocialLinkContent>
                                        </Box>
                                    </SocialLink>
                                </Grid>
                                <Grid item>
                                    <Box pt={5}>
                                        <LangSelector />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box py={4}>
                        <GradientDivider />
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item>
                                <Typography fontFamily={"Avenir Next"} fontSize="12px" color="#F2FEFF">
                                    {t("footer.all_rights_reserved")}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Grid container columnSpacing={3} justifyContent={"right"}>
                                    <Grid item>
                                        <FooterLink to={"/todo"}>
                                            {t("footer.terms_and_conditions")}
                                        </FooterLink>
                                    </Grid>
                                    <Grid item>
                                        <FooterLink to={"/todo"}>
                                            {t("footer.privacy_policy")}
                                        </FooterLink>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DarkContainer>
        </Box>
    )
}

export default Footer