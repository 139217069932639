import { ReactNode } from "react";
import { matchPath, useLocation, useParams } from "react-router";
import { Role, RoleType } from "../models/Role";
import MenuBookIcon from '@mui/icons-material/MenuBook';

export interface PageRoute {
    path: string;
    icon?: ReactNode;
    title: string;
    roles: RoleType[];
}

export interface HeaderRoute {
    mainRoute: PageRoute;
    subRoutes: PageRoute[];
    icon: ReactNode;
    title: string;
    roles: RoleType[];
    restoSpecific: boolean;
}

export function findMatchingRoute(path: string, routes: string[]) {
    return routes.find(route => matchPath(route, path) != null);
}

export function findMatchingPageRoute(path: string, pageRoutes: PageRoute[]) {
    return pageRoutes.find(route => matchPath(route.path, path) != null);
}

export function isRouteAccessible(route: PageRoute, userRoles: Role[]) {
    const roleTypes = userRoles.map(role => role.type);
    return route.roles.some(role => roleTypes.includes(role));
}

export function isHeaderAccessible(headerRoute: HeaderRoute, userRoles: Role[]) {
    if (headerRoute.roles.length === 0) return true;
    const roleTypes = userRoles.map(role => role.type);
    return headerRoute.roles.some(role => roleTypes.includes(role));
}

export const welcomePageRoute: PageRoute = {
    path: "/",
    title: "Home",
    roles: []
};

export const loginPageRoute: PageRoute = {
    path: "/login/:lang",
    title: "navigation.login",
    roles: []
};

export const confirmSignupRoute: PageRoute = {
    path: "/confirm-signup/:userId/:token/:lang",
    title: "navigation.confirm_signup",
    roles: []
};

export const forgottenPasswordRoute: PageRoute = {
    path: "/forgotten-password/:lang",
    title: "navigation.forgotten_password",
    roles: []
};

export const resetPasswordRoute: PageRoute = {
    path: "/reset-password/:userId/:token/:lang",
    title: "navigation.reset_password",
    roles: []
};

export const signUpSuccessRoute: PageRoute = {
    path: "/signup-successful/:lang",
    title: "navigation.signup_successful",
    roles: []
};

export const menuEditorRoute: PageRoute = {
    path: "/:lang",
    title: "navigation.menu_editor",
    roles: []
}

export const restrictedRoutes = [
    welcomePageRoute,
    menuEditorRoute
];

export const headerRoutes: HeaderRoute[] = [
    {
        mainRoute: menuEditorRoute,
        subRoutes: [],
        icon: <MenuBookIcon />,
        title: "navigation.menu_editor",
        roles: [RoleType.MENU_EDITOR],
        restoSpecific: false
    }
];

export const liveRoutes = [
    menuEditorRoute,
    loginPageRoute
]

export function usePathReconstructor() {
    const params = useParams();
    const location = useLocation();
    const liveRouteStrings = liveRoutes.map(route => route.path);

    function replaceParam(propName: string, value: string) {
        let resultPath = findMatchingRoute(location.pathname, liveRouteStrings);
        if (resultPath == null) return null;;
        for (const key in params) {
            if (key === propName) {
                resultPath = resultPath.replace(`:${key}`, value);
            } else if (params[key] != null) {
                resultPath = resultPath.replace(`:${key}`, params[key] as string);
            }
        }
        return resultPath;
    }

    function copyParamsFromPath(targetPath: string) {
        let resultPath = targetPath;
        for (const key in params) {
            resultPath = resultPath.replace(`:${key}`, params[key] as string);
        }
        return resultPath;
    }

    return {
        replaceParam,
        copyParamsFromPath,
    }
}