import { Grid } from '@mui/material'
import { LangSelectLangButton, LangSelectTextfieldContainer, LangSelectTextfieldInput } from '../styles/buttons'
import { ThemeProvider } from 'styled-components'
import { availableLang } from '../models/Lang'

type Props = {
    value: string;
    setValue: (value: string) => void;
    selectedLang: availableLang;
    setSelectedLang: (lang: availableLang) => void;
}

const LangSelectorTextfield = (props: Props) => {
    return (
        <>
            <LangSelectTextfieldContainer>
                <Grid container>
                    <Grid item xs>
                        <LangSelectTextfieldInput
                            value={props.value}
                            onChange={(e) => props.setValue(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={0.8} style={{ height: "20px" }}>
                            <Grid item>
                                <ThemeProvider theme={{ isSelected: props.selectedLang === "hu" }}>
                                    <LangSelectLangButton
                                        onClick={() => props.setSelectedLang("hu")}
                                    >
                                        <img
                                            src={"../assets/flag-hu.svg"}
                                            width={"20px"}
                                            height={"20px"}
                                        />
                                    </LangSelectLangButton>
                                </ThemeProvider>
                            </Grid>
                            <Grid item >
                                <ThemeProvider theme={{ isSelected: props.selectedLang === "en"  }}>
                                    <LangSelectLangButton
                                        onClick={() => props.setSelectedLang("en")}
                                    >
                                        <img
                                            src={"../assets/flag-en.svg"}
                                            width={"20px"}
                                            height={"20px"}
                                        />
                                    </LangSelectLangButton>
                                </ThemeProvider>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LangSelectTextfieldContainer>
        </>
    )
}

export default LangSelectorTextfield