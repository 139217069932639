import { Grid } from '@mui/material'
import React, { useRef } from 'react'
import { SearchInputButton, SearchInputContainer, SearchInputField } from '../styles/searchInput'
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

type Props = {
    search: string,
    setSearch: (search: string) => void
}

const SearchInput = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    function onContainerClick() {
        inputRef.current?.focus();
    }

    function handleInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
        props.setSearch(e.target.value);
    }

    return (
        <SearchInputContainer>
            <Grid container style={{ height: "100%" }}>
                <Grid item onClick={onContainerClick}>
                    <SearchIcon style={{ marginTop: "12px", marginLeft: "10px" }} />
                </Grid>
                <Grid item xs onClick={onContainerClick}>
                    <SearchInputField
                        ref={inputRef}
                        value={props.search}
                        onChange={handleInputChanged}
                    />
                </Grid>
                <Grid item>
                    <SearchInputButton>
                        {t("common.search")}
                    </SearchInputButton>
                </Grid>
            </Grid>
        </SearchInputContainer>
    )
}

export default SearchInput