export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const userProfilePath = constructApiRoute(`/user/profile`);
export const uploadImagePath = constructApiRoute(`/s3/upload-img`);
export const searchImagesPath = constructApiRoute(`/s3/search-img`);
export const checkImageUsePath = constructApiRoute("/s3/check-use");
export const deleteImagePath = constructApiRoute("/s3/delete");
export const searchRestaurantSettingsPath = constructApiRoute("/restaurant/search?query=");
export const putCreateUser = constructApiRoute("/user/create");
export const putConfirmSignup = constructApiRoute("/user/confirm-sign-up");
export const postRequestPasswordReset = constructApiRoute("/user/forgotten-password");
export const postResetPassword = constructApiRoute("/user/reset-password");
export const getAccessibleUsers = constructApiRoute("/user/list");
export const putUpdateUser = constructApiRoute("/user/update");
export const getFullMenu = constructApiRoute("/menu/all/:restaurantShortName");
export const postUpdateMenu = constructApiRoute("/menu/update-menu/:restaurantShortName");
export const postUploadItemImage = constructApiRoute("/menu/upload-item-image/:restaurantShortName");

export function constructApiRoute(path: string) {
    return `${baseUrl}${path}`;
}