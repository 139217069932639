import { Box, MenuItem, Paper, styled } from "@mui/material";
import { styled as styledComponent } from "styled-components";
import { background, backgroundAlt, fadedBlue, inputBorder, primary, secondary, secondaryText } from "./colors";

export const HoverablePaper = styled(Paper)`
    img{
        cursor: pointer;
    }
    transition: 0.3s background;
    border-radius: 10px;
    &:hover {
        background-color: #292727;
    }
`;

export const MainContainer = styledComponent.div`
    background: ${secondaryText};
`;

export const LoginLangSelector = styledComponent(Box)`
    background: ${secondaryText};
    border-bottom-left-radius: 10px;
`;

export const UserRoleChip = styledComponent.div`
    background: ${primary};
    color: white;
    padding: 5px 10px;
    padding-top: 5px;
    padding-bottom: 0;
    border-radius: 15px;
    display: inline-block;
    svg{
        font-size: 1.2rem;
        margin-top: -1px;
    }
`;

export const RoundedMainContainer = styled(Box)`
    background: ${background.dark};
    border-radius: 12px;
`;

export const RoundedWhiteContainer = styled(Box)`
    background: white;
    border-radius: 12px;
`;

export const InputContainer = styledComponent.div`
    border-radius: 16px;
    border: 1px solid ${inputBorder};
    border-radius: 16px;
    height: 34px;
    background: white;
`;

export const MenuItemEditRowContainer = styledComponent(Box)`
    background: ${backgroundAlt};
    border-bottom: 1px solid #4399A666;
`;

export const DragHandleContainer = styledComponent(Box)`
    cursor: grab;
    &:active{
        cursor: grabbing;
    }
`;

export const CenterContainer = styled(Box)`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const CenterContent = styled(Box)`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
//login-bg.png

export const LoginPageContainer = styled(Box)`
    background: url("${process.env.REACT_APP_PUBLIC_URL}/assets/login-bg.png") no-repeat;
    background-position: center;
    background-size: cover;
`;

export const LoginPageContainerOverlay = styled(Box)`
    background: rgba(15, 40, 44, 0.85);
`;

export const DarkContainer = styled(Box)`
    background: ${primary.deep};
    border-radius: 45px;
`;

export const LangSelectorContainer = styledComponent.div`
    background: rgba(102, 234, 253, 0.2);
    border-radius: 65px;
    width: auto;
`;

export const GradientDivider = styledComponent.div`
  background: rgb(212,207,207);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  height: 2px;
`;

export const LoginForm = styled(Box)`
    background: ${fadedBlue};
    border-radius: 20px;
`;

export const ButtonSelectMenu = styledComponent(MenuItem)`
    background: ${props => props.theme.isSelected ? background.dark : "white"};
    font-weight: ${props => props.theme.isSelected ? "bold" : "normal"};
    &:hover{
        background: ${props => props.theme.isSelected ? background.pale : undefined} !important;
    }
`;