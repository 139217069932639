import styled from "styled-components";
import { background, inputBg, primary } from "./colors";

export const SearchInputContainer = styled.div`
    background: ${inputBg};
    border-radius: 30px;
    height: 50px;
`;

export const SearchInputField = styled.input`
    border: none;
    background: none;
    width: 100%;
    font-size: 1.1em;
    color: black;
    margin-top: 12px;
    margin-right: 3px;
    font-family: Inter;
    &:focus {
        outline: none;
    }
`;

export const SearchInputButton = styled.button`
    background: ${background.deep};
    color: black;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    margin-left: 10px;
    cursor: pointer;
    height: 100%;
    font-size: 15px;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focus {
        outline: none; 
    }
    &:hover{
        background: ${primary.pale};
        box-shadow: 0px 0px 5px 0px ${primary.pale};
    }
`;