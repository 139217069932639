import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { findMatchingRoute, loginPageRoute, restrictedRoutes } from '../config/pageRoutes';
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useTranslation } from 'react-i18next';

type AuthProviderProps = {
    children?: any;
}

type AuthContextProps = {
    authState: AuthState;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => void;
    user: AuthUser | null;
    getToken: () => Promise<string | undefined>;
    googleLogin: () => Promise<boolean>;
    errorMessage: string | null;
    setErrorMessage: (message: string | null) => void;
}

const AuthContext = createContext<AuthContextProps>({} as any);

export enum AuthState {
    LOADING,
    LOGGED_IN,
    LOGGED_OUT,
}

const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
});

interface AuthUser {
    email: string;
    token: string;
}

export const AuthProvider = (props: AuthProviderProps) => {
    const [authState, setAuthState] = React.useState<AuthState>(AuthState.LOADING);
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState<AuthUser | null>(null);
    const { i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    
    useEffect(() => {
        initAuth();
    }, []);

    async function initAuth() {
        //default firebase TS is wrong
        getAuth(firebaseApp).onAuthStateChanged((u: any) => {
            if (u != null && u.email != null && u.accessToken != null) {
                setUser({
                    email: u.email,
                    token: u.accessToken
                });
                setAuthState(AuthState.LOGGED_IN);
                setErrorMessage(null);
            } else {
                setUser(null);
                setAuthState(AuthState.LOGGED_OUT);
            }
        });
    }

    async function getToken() {
        return await getAuth(firebaseApp).currentUser?.getIdToken();
    }

    useEffect(() => {
        if (authState === AuthState.LOGGED_OUT) {
            checkForNonAuthRedirect();
        }
    }, [authState]);

    function checkForNonAuthRedirect() {
        const restrictedRoutePaths = restrictedRoutes.map(route => route.path);
        const matchingRestrictedRoute = findMatchingRoute(location.pathname, restrictedRoutePaths);
        if (matchingRestrictedRoute) {
            navigate(loginPageRoute.path.replace(":lang", i18n.language));
        }
    }

    async function login(email: string, password: string) {
        try {
            
            const userData = await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);
            if (userData.user == null || userData.user.email == null) {
                return false;
            }
            setUser({
                email: userData.user.email,
                token: await userData.user.getIdToken()
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async function googleLogin() {
        try {
            const credentials = await signInWithPopup(getAuth(firebaseApp), new GoogleAuthProvider());
            const user = credentials.user;
            if (user == null || user.email == null) {
                return false;
            }
            setUser({
                email: user.email,
                token: await user.getIdToken()
            });

            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    function logout() {
        signOut(getAuth(firebaseApp));
    }

    const state = {
        authState,
        login,
        logout,
        user,
        getToken,
        googleLogin,
        errorMessage,
        setErrorMessage
    }

    return (
        <AuthContext.Provider value={state}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext