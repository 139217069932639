import { Alert, AlertTitle, Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router'
import SnackbarContext from '../contexts/SnackbarContext'
import { resetPasswordDto } from '../models/User'
import { postResetPassword } from '../config/apiRoutes'
import { PrimaryLink } from '../styles/links'
import { loginPageRoute, usePathReconstructor } from '../config/pageRoutes'

type Props = {}

const ResetPasswordPage = (props: Props) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationEnabled, setValidationEnabled] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const snackbar = useContext(SnackbarContext);
  const params = useParams();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const { copyParamsFromPath } = usePathReconstructor();

  function handleNewPasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setNewPassword(e.target.value);
  }

  function handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setConfirmPassword(e.target.value);
  }

  useEffect(() => {
    if (newPassword.length < 8) {
      setNewPasswordError(t("auth.password_min_length"));
    } else {
      setNewPasswordError("");
    }
  }, [newPassword]);

  useEffect(() => {
    if (confirmPassword !== newPassword) {
      setConfirmPasswordError(t("auth.passwords_do_not_match"));
    } else {
      setConfirmPasswordError("");
    }
  }, [confirmPassword, newPassword]);

  async function handleConfirmClick() {
    setValidationEnabled(true);
    if (newPasswordError.length > 0 || confirmPasswordError.length > 0) return;
    const { token, userId } = params;
    if (token == null || userId == null) {
      return snackbar.showMessage(t("auth.invalid_token"), "error");
    }
    const data: resetPasswordDto = {
      userId: parseInt(userId),
      token: token,
      password: newPassword
    }
    const response = await fetch(postResetPassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      setShowSuccessMessage(true);
    } else {
      snackbar.showMessage(t("auth.invalid_token"), "error");
    }
  }

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "auto" }}>
        <Box pt={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography fontSize="1.4rem" pb={2}>
                {t("auth.change_password_title")}
              </Typography>
              <Box>
                <Box py={1}>
                  <TextField
                    label={t("auth.password")}
                    type="password"
                    name="new-password"
                    fullWidth
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    error={validationEnabled && newPasswordError.length > 0}
                    helperText={validationEnabled && newPasswordError}
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    label={t("auth.confirm_password")}
                    type="password"
                    fullWidth
                    name="confirm-new-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={validationEnabled && confirmPasswordError.length > 0}
                    helperText={validationEnabled && confirmPasswordError}
                  />
                </Box>
                <Box py={1}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleConfirmClick}
                  >
                    {t("auth.set_password")}
                  </Button>
                </Box>
                {showSuccessMessage && (
                  <Box pt={1}>
                    <Alert variant="filled" style={{ color: "white" }}>
                      <AlertTitle>
                        {t("auth.change_password_success")}
                      </AlertTitle>
                      <PrimaryLink
                        to={copyParamsFromPath(loginPageRoute.path)}
                      >
                        {t("auth.continue_to_login")}
                      </PrimaryLink>
                    </Alert>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </div>
    </>

  )
}

export default ResetPasswordPage