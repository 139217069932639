import { MenuItem, SerializedMenuItem, deserializeMenuItem, deserializeMenuItems } from "./MenuItem";
import { MenuCategoryTranslation, MenuItemTranslation } from "./Translation";
import { normalizeString } from "./common";

export interface SerializedMenuCategory {
    id: number;
    restaurantShortName: string;
    isActive: boolean;
    translations: MenuCategoryTranslation[];
    menuItems: SerializedMenuItem[];
    order: number;
}

export interface MenuCategory {
    id: number;
    restaurantShortName: string;
    isActive: boolean;
    translations: MenuCategoryTranslation[];
    menuItems: MenuItem[];
    order: number;
}

export interface MenuCategorySearchResult {
    category: MenuCategory;
    items: MenuItem[];
    searchType: MenuCategorySearchType;
}

export enum MenuCategorySearchType {
    CATEGORY,
    ITEM,
    NONE
}

export function deserializeMenuCategory(serialized: SerializedMenuCategory): MenuCategory {
    return {
        id: serialized.id,
        restaurantShortName: serialized.restaurantShortName,
        isActive: serialized.isActive,
        translations: serialized.translations,
        menuItems: deserializeMenuItems(serialized.menuItems),
        order: serialized.order,
    };
}

export function deserializeMenuCategories(serialized: SerializedMenuCategory[]): MenuCategory[] {
    const deserialized = serialized.map(deserializeMenuCategory);
    return orderMenuCategories(deserialized);
}

export function orderMenuCategories(categories: MenuCategory[]): MenuCategory[] {
    return categories.sort((a, b) => a.order - b.order);
}

export const defaultMenuCategoryTranslations: MenuItemTranslation[] = [
    {
        id: -1,
        title: "",
        description: "",
        isoCode: "hu"
    },
    {
        id: -2,
        title: "",
        description: "",
        isoCode: "en"
    }
]

export function generateCategorySearch(category: MenuCategory, search: string): MenuCategorySearchResult {
    const normalizedSearch = normalizeString(search);
    const isFullSearch = category.translations.some(translation => {
        if (normalizeString(translation.title).includes(normalizedSearch)) return true;
        return false;
    });
    if (isFullSearch) {
        return {
            category,
            items: category.menuItems,
            searchType: MenuCategorySearchType.CATEGORY
        }
    }
    const matchingItems = category.menuItems.filter(item => {
        return item.translations.some(translation => {
            if (normalizeString(translation.title).includes(normalizedSearch)) return true;
            if (translation.description && normalizeString(translation.description).includes(normalizedSearch)) return true;
            return false;
        });
    });
    if (matchingItems.length > 0) {
        return {
            category,
            items: matchingItems,
            searchType: MenuCategorySearchType.ITEM
        }
    }
    return {
        category,
        items: [],
        searchType: MenuCategorySearchType.NONE
    }
}

