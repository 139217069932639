import { Menu, MenuItem, Divider, Grid } from '@mui/material';
import React from 'react'
import { ButtonSelect } from '../styles/buttons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ThemeProvider } from 'styled-components';
import { ButtonSelectMenu } from '../styles/containers';

export interface SelectMenuOption {
    value: string;
    label: string;
}

type Props = {
    options: SelectMenuOption[];
    selectedValue: string | null;
    onChange: (value: string | null) => void;
}

const SelectButton = (props: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function selectedLabel() {
        if (props.selectedValue == null) {
            return "";
        }
        const selectedOption = props.options.find(option => option.value === props.selectedValue);
        return selectedOption?.label ?? "";
    }

    function handleSelectClick(value: string | null) {
        props.onChange(value);
        handleClose();
    }

    return (
        <>
            <ButtonSelect
                onClick={handleClick}
            >
                <Grid container>
                    <Grid item xs textAlign={"left"}>
                        {selectedLabel()}
                    </Grid>
                    <Grid item textAlign={"right"}>
                        <ArrowDropDownIcon />
                    </Grid>
                </Grid>

            </ButtonSelect>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {props.options.map(option => {
                    return <ThemeProvider key={option.value} theme={{ isSelected: option.value === props.selectedValue }}>
                        <ButtonSelectMenu onClick={() => handleSelectClick(option.value)}>
                            {option.label}
                        </ButtonSelectMenu>
                    </ThemeProvider>
                })}
            </Menu>
        </>
    )
}

export default SelectButton