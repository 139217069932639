import { Alert, AlertTitle, Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SnackbarContext from '../contexts/SnackbarContext'
import { validateEmail } from '../models/validate'
import { postRequestPasswordReset } from '../config/apiRoutes'

type Props = {}

const ForgottenPasswordPage = (props: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [isValidationEnabled, setIsValidationEnabled] = useState<boolean>(false);
  const snackbar = useContext(SnackbarContext);

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  useEffect(() => {
    if (validateEmail(email)) {
      setEmailError("");
    } else {
      setEmailError(t("auth.invalid_email"));
    }
  }, [email]);

  async function handleSendMailClick() {
    setIsValidationEnabled(true);
    if (emailError.length > 0) {
      return;
    }
    try {
      sendMail();
    } catch (e) {
      snackbar.showMessage(t("auth.reset_email_error"), "error");
    }
  }

  async function sendMail() {
    const response = await fetch(postRequestPasswordReset, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    });
    if (response.ok) {
      setShowSuccessMessage(true);
    } else {
      snackbar.showMessage(t("auth.reset_email_error"), "error");
    }
  }

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "auto" }}>
        <Box pt={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography fontSize="1.4rem">
                {t("auth.forgotten_password")}
              </Typography>
              <Typography py={3} pt={2}>
                {t("auth.reset_password_text")}
              </Typography>
              <Box>
                <TextField
                  label={t("auth.email")}
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  error={isValidationEnabled && emailError.length > 0}
                  helperText={isValidationEnabled && emailError}
                />
              </Box>
              <Box pt={3}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSendMailClick}
                >
                  {t("auth.send_reset_mail")}
                </Button>
              </Box>
            </Box>
            {showSuccessMessage && (
              <Box p={1}>
                <Alert variant="filled" style={{ color: "white" }}>
                  <AlertTitle>
                    {t("auth.reset_sent")}
                  </AlertTitle>
                  {t("auth.send_reset_mail_success")}
                </Alert>
              </Box>
            )}
          </Paper>
        </Box>
      </div>

    </>
  )
}

export default ForgottenPasswordPage