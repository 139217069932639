import React, { useContext, useMemo, useState } from 'react'
import { HeaderButton, HeaderContainer, HeaderLinkContainer, HeaderLogo } from '../styles/header'
import { headerRoutes, isHeaderAccessible } from '../config/pageRoutes';
import UserContext from '../contexts/UserContext';
import HeaderLink from './HeaderLink';
import { ThemeProvider } from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Stack } from '@mui/material';
import AuthContext from '../contexts/AuthContext';

type Props = {}

const Header = (props: Props) => {
    const { user } = useContext(UserContext);
    const [isHeaderOpoen, setIsHeaderOpen] = useState(false);
    const { logout } = useContext(AuthContext);

    const displayedRoutes = useMemo(() => {
        return headerRoutes.filter(route => isHeaderAccessible(route, user.roles));
    }, [user.roles]);

    function handleLogoutClick() {
        logout();
    }

    return (
        <>
            <ThemeProvider theme={{ isOpen: isHeaderOpoen }}>
                <HeaderContainer>
                    <HeaderLogo
                        src={"../assets/logo.svg"}
                    />
                    {displayedRoutes.map(route =>
                        <HeaderLink
                            key={route.mainRoute.path}
                            route={route}
                        />
                    )}
                    <HeaderLinkContainer>
                        <HeaderButton onClick={handleLogoutClick}>
                            <Stack>
                                <LogoutIcon />
                            </Stack>
                        </HeaderButton>
                    </HeaderLinkContainer>
                </HeaderContainer>
            </ThemeProvider>
        </>
    )
}

export default Header