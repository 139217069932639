import commonEn from "./commonEn";
import authEn from "./authEn";
import menuEn from "./menuEn";
import navigationEn from "./navigationEn";
import orderEn from "./orderEn";
import paymentEn from "./paymentEn";
import galleryEn from "./galleryEn";
import footerEn from "./footerEn";

const mainEn = {
    common: commonEn,
    navigation: navigationEn,
    menu: menuEn,
    order: orderEn,
    payment: paymentEn,
    auth: authEn,
    gallery: galleryEn,
    footer: footerEn
}

export default mainEn;